<template>
  <div class="header">
    <div class="header__logo">
      <router-link class="header__logo-link" :to="{ name: 'home' }">
        Brewsell
      </router-link>
    </div>
    <div v-if="!isMobile" class="header__links">
      <router-link class="header__link" :to="{ name: 'menu' }"
        >Меню</router-link
      >
      <router-link class="header__link" :to="{ name: 'vacancies' }"
        >Вакансии</router-link
      >
    </div>
    <div v-else @click="showMobileMenu = true">
      <img :src="require('@/assets/List.svg')" />
    </div>
    <mobile-menu :show-menu="showMobileMenu" @close="showMobileMenu = false" />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import MobileMenu from "@/components/MobileMenu/MobileMenu.vue";

export default defineComponent({
  name: "BaseHeader",
  components: { MobileMenu },
  setup() {
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => {
      return windowWidth.value < 768;
    });
    const showMobileMenu = ref(false);
    return {
      isMobile,
      windowWidth,
      showMobileMenu,
    };
  },
});
</script>
<style lang="scss">
.header {
  padding: 18px 0 18px 0;
  display: flex;
  justify-content: space-between;
  &__logo {
    font-family: Unbounded, serif;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }
  &__logo-link {
    color: #0f172a;
    text-decoration: none;
  }
  &__links {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  &__link {
    color: #0f172a;
    text-decoration: none;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
