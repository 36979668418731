<template>
  <div
    class="title"
    :style="{ color: textColor, fontSize: fontSize ? fontSize : '', textAlign: textAlign }"
  >
    <div v-if="customTitle" class="custom">
      <slot name="customTitle"></slot>
    </div>
    <div v-else>
      {{ titleText }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseTitle",
  props: {
    fontSize: {
      type: String,
    },
    titleText: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: "#0f172a",
    },
    textAlign: {
      type: String,
      default: 'left',
    },
    customTitle: {
      type: Boolean,
      default: false,
    }
  },
});
</script>
<style lang="scss">
.title {
  font-family: Unbounded, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}
</style>
