<template>
  <div class="description" :style="{ textAlign: textAlign, color: textColor }">
    {{ descriptionText }}
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseDescription",
  props: {
    descriptionText: {
      type: String,
      required: true,
    },
    textAlign: {
      type: String,
      default: "center",
    },
    textColor: {
      type: String,
      default: "#0f172a",
    }
  },
});
</script>
<style lang="scss">
.description {
  text-align: center;
  font-family: Inter, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media (max-width: 768px) {
  .description {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
