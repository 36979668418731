<template>
  <transition name="fade">
    <div v-if="showMenu" class="mobile-menu">
      <div class="mobile-menu__header">
        <router-link class="mobile-menu__header-link" :to="{ name: 'home' }">
          Brewsell
        </router-link>
        <div class="mobile-menu__header-close" @click="$emit('close')">
          <img :src="require('@/assets/X.svg')" />
        </div>
      </div>
      <div class="mobile-menu__menu-wrapper">
        <div class="mobile-menu__menu-list">
          <router-link
            class="mobile-menu__link"
            :to="{ name: 'menu' }"
            @click="$emit('close')"
            >Меню</router-link
          >
          <router-link
            class="mobile-menu__link"
            :to="{ name: 'vacancies' }"
            @click="$emit('close')"
            >Вакансии</router-link
          >
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MobileMenu",
  props: { showMenu: { type: Boolean, required: true } },
});
</script>
<style lang="scss">
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #224476;
  z-index: 10;
  &__header {
    padding: 32px 15px 0 15px;
    display: flex;
    justify-content: space-between;
    font-family: Unbounded, serif;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }
  &__header-link {
    color: #ffffff;
    text-decoration: none;
  }
  &__menu-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__menu-list {
    position: relative;
    bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
  }
  &__link {
    color: #ffffff;
    text-decoration: none;
    font-family: Unbounded, serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 146.5%;
    text-transform: uppercase;
    &.router-link-active {
      font-weight: 700;
    }
  }
}
@keyframes zoom-in {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes zoom-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
}
.fade-enter-active {
  animation: zoom-in 0.5s ease-in-out;
}
.fade-leave-active {
  animation: zoom-out 0.5s ease-in-out;
}
</style>
