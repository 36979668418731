import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "custom"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "title",
    style: _normalizeStyle({ color: _ctx.textColor, fontSize: _ctx.fontSize ? _ctx.fontSize : '', textAlign: _ctx.textAlign })
  }, [
    (_ctx.customTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "customTitle")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.titleText), 1))
  ], 4))
}